@import 'variables';

.bg-dark {
    background-color: transparent !important;

    .container {
        .navbar-collapse {
            flex-grow: 0;
            text-transform: uppercase;
            font-weight: 600;
        }
    }
}

.dropdown-item.active {
    background-color: $majalMain;
}

@media screen and (max-width: 680px) {
    .bg-dark {
        background-color: $transparent-black !important;
    }
}