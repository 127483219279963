@import 'variables';

#mjl-container-about-us {
    padding: 3rem 0px;

    .icon-box i:hover {
        text-shadow: 0px 0px 10px $black, 0 1px $black, 1px 0 $black, 0 -1px $black;
    }

    .mjl-about {
        overflow: hidden;

        &--header {

            width: 100%;
            height: 90vh;
            margin: 0;
            perspective: 1px;
            transform-style: preserve-3d;
            overflow-x: hidden;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                display: none;
            }


            &-hero {
                max-width: 1200px;
                height: 90vh;
                position: relative;
                transform-style: preserve-3d;
                margin: auto;

                &::before {
                    content: "";
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    background: url('../../../public/assets/images/about-us/majal-members-hero.jpg');
                    background-size: cover;
                    transform: translateZ(-1px) scale(2.2);
                    background-position: bottom;
                }

                .mjl-about--header-title {
                    top: 2%;
                    left: 50%;
                    position: absolute;
                    font-size: 5rem;
                    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3), 5px 5px 70px rgba(255, 255, 255, 0.5);
                    transform: scale(1, 1.1) translate(-50%, 10%);
                    text-align: center;

                    img {
                        width: 250px;
                    }

                    .majal-title {
                        font-size: 4rem;
                        color: $black;
                    }

                    p {
                        font-size: medium;
                    }
                }

                .mjl-about--header-subtitle {
                    text-align: center;
                    color: $white;
                    bottom: -25%;
                    position: absolute;
                    width: 100%;

                    p {
                        margin: auto;
                        width: 50%;
                        background-color: $majalMain;
                        padding: 1rem;
                    }
                }
            }
        }

        &--description {
            display: none;
            text-align: center;
            padding: 3rem 0rem;
        }

        &--band-members {

            h1.majal-title {
                padding: 2rem 0rem;
                font-size: 4rem;
                text-align: center;
                text-transform: uppercase;
            }

            .card {
                padding: 1rem 0rem;
                background: transparent;
                color: $white;
                border: 0px;
                border-radius: 0px;
                border-top: 2px solid $transparent-black;

                img {
                    box-shadow: 0px 0px 10px 0px $white;
                }

                .card-body {
                    .card-title {
                        &.majal-title {
                            font-size: 4rem;
                        }
                    }

                    .card-text {
                        &.majal-title {
                            font-size: 1.5rem;
                        }

                        &.mjl-description {
                            text-align: justify;
                        }

                        &.social-media {
                            justify-content: flex-start;

                            .icon-box {
                                justify-content: flex-start;
                            }
                        }
                    }
                }

                &:nth-child(odd) {
                    .row {
                        flex-direction: row-reverse;
                    }
                }
            }
        }

        &--social {
            border-bottom: 2px solid $transparent-black;
        }
    }
}

@media screen and (max-width: 680px) {
    #mjl-container-about-us {
        padding: 4rem 0px;

        .mjl-about {

            &--header {
                height: 40vh;

                &-hero {
                    width: 100%;
                    height: 40vh;

                    &::before {
                        height: 40vh;
                        content: "";
                        background: url('../../../public/assets/images/about-us/majal-members-hero.jpg');
                        width: 100%;
                        position: absolute;
                        background-size: contain;
                        background-repeat: no-repeat;
                        transform: unset;
                        background-position: top;
                    }
                }

                .mjl-about--header-title {
                    top: 5%;
                    left: 50%;
                    font-size: 1rem;

                    img {
                        width: 100px;
                    }

                    .majal-title {
                        font-size: 1rem;
                        color: $black;
                    }

                    p {
                        font-size: medium;
                    }
                }

                .mjl-about--header-subtitle {
                    display: none;
                }
            }

            &--description {
                display: block;
            }

            &--band-members {
                h1.majal-title {
                    padding: 2rem 0rem;
                    font-size: 3rem;
                    text-align: center;
                    text-transform: uppercase;
                }

                .card {
                    .card-body {
                        text-align: center;

                        .card-title {
                            &.majal-title {
                                font-size: 3rem;
                            }
                        }

                        .card-text {
                            &.majal-title {
                                font-size: 1.5rem;
                            }
                        }
                    }
                }
            }
        }
    }
}