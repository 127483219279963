@import 'variables';

.mjl-container-home {
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &--header {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    img {
        width: 350px;
    }

    .majal-title {
        font-size: 5rem;
    }
}

#hero-background,
.hero-background {
    background-position: center;
    background-size: "cover";
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    z-index: -1;
}

@media screen and (max-width: 480px) {

    .mjl-container-home {
        img {
            width: 175px;
        }

        .majal-title {
            font-size: 2.5rem;
        }
    }
}