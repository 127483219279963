@import 'variables';

.music-container {
    padding-top: 4rem;

    #music {

        .card {
            background-color: $black;
            border: 1px solid $white;

            img {
                width: 100%;
            }

            .card-text {
                text-align: justify;
                color: white;

                ol {
                    list-style: decimal;
                    padding-left: 2rem;
                }
            }

            .social-media {
                padding-top: 2rem;
                justify-content: flex-start;
            }
        }
    }
}