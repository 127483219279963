#gallery-view {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;
    padding: 5rem 12px;

    .pics {
        -webkit-transition: all 350ms ease;
        transition: all 350ms ease;
        cursor: pointer;
        margin-bottom: 12px;

        &:hover {
            filter: opacity(0.8);
        }
    }
}

.modal {

    .modal-dialog {
        max-width: 800px;

        .modal-content {
            background-color: #6c3308b3;
        }
    }
}

.modal-view-btns {
    display: flex;
    justify-content: flex-end;

    .btn-primary {
        margin: 0;
        background-color: rgba(0, 0, 0, 0.50);
    }
}

@media (max-width: 991px) {
    #gallery-view {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }
}

@media (max-width: 480px) {
    #gallery-view {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        -moz-column-width: 100%;
        column-width: 100%;
    }
}