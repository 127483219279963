#gallery {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    max-width: 1200px;
    flex-wrap: wrap;
    margin: auto;

    .card {
        position: relative;
        width: 280px;
        height: 400px;
        margin: 30px;
        box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
        border-radius: 15px;
        background: rgba(255, 255, 255, 0.1);
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid rgba(255, 255, 255, 0.5);
        border-left: 1px solid rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(5px);
        transition: all 0.2s ease-in-out;

        .content {
            padding: 20px;
            text-align: center;

            h2 {
                position: absolute;
                top: -40px;
                right: 30px;
                font-size: 6em;
                font-weight: 600;
                color: rgba(255, 255, 255, 0.05);
                pointer-events: none;
            }

            h3 {
                font-size: 1.8em;
                color: #FFFFFF;
                padding-top: 1rem;
            }

            p {
                font-size: 1em;
                color: #FFFFFF;
                font-weight: 300;
            }

            .avatar img {
                border-radius: 50%;
                width: 150px;
            }
        }

        &:hover {
            transform: translateY(10px);
        }
    }
}

@media screen and (max-width: 480px) {

    #gallery {
        padding-top: 3rem;
    }
}