@use 'gallery';
@use 'galleryView';
@use 'musicView';
@use 'navbar';
@use 'home';
@use 'aboutUsView';

/* Additional imports */
@import 'variables';
@import 'palette';
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css");

/* Layout */
* {
	box-sizing: border-box;
	transition: all .2s ease-in-out;
}

body {
	background-color: $majalMain;
	color: $white;
}

.majal-title {
	font-family: 'JayaGiri', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
	color: $white;
}

/* Basic */
@media screen and (max-width: 480px) {

	html,
	body {
		min-width: 320px;
	}

	.footer-fixed {
		position: inherit !important;
		background-color: $majalMain;
	}
}

a {
	transition: color 0.2s ease, border-color 0.2s ease;
	color: inherit;
	text-decoration: none;
}

a:before {
	-moz-transition: color 0.2s ease, text-shadow 0.2s ease;
	-webkit-transition: color 0.2s ease, text-shadow 0.2s ease;
	-ms-transition: color 0.2s ease, text-shadow 0.2s ease;
	transition: color 0.2s ease, text-shadow 0.2s ease;
}

a:hover {
	color: $majalMain;
}

@media screen and (max-width: 768px) {

	.modal-music-preview {
		width: 100%;
	}

}

.social-media {
	display: flex;
	justify-content: center;
}

.mjl-tostify-container {
	z-index: 999;
}

// Elements

.icon-box {
	display: flex;
	justify-content: center;
	text-decoration: none;
	width: 50px;
	height: 50px;
	color: $white;
}

.icon-box i::before {
	font-size: xx-large;
	width: 25px;
	height: 25px;
}

.icon-box i:hover {
	color: $majalMain !important;
}

.btn-primary {
	background-color: transparent;
	position: relative;
	display: inline-block;
	padding: 8px 20px;
	margin-top: 15px;
	border: 1px solid #FFFFFF;
	color: #FFFFFF;
	border-radius: 20px;
	text-decoration: none;
	font-weight: 500;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
	align-items: center;
	z-index: 2 !important;

	&:hover {
		background-color: #FFFFFF;
		text-decoration: none;
		color: #000;
	}

	&:active {
		background-color: transparent !important;
		border-color: #FFFFFF !important;
	}

	&:focus {
		background-color: transparent !important;
		border-color: #FFFFFF !important;
		box-shadow: 0 0 0 0.2rem rgb(255 255 255 / 50%);
	}

	span {
		margin-left: 5px;
	}
}

/* HOME SECTION */

.modal-music-preview {
	width: 400px;

	.modal-content {
		background-color: $transparent-black !important;

		.modal-body {

			.card {
				.card-body {
					background-color: $black;
					text-align: center;
					border-top: 1px solid $white;
					color: $white;

					.card-text {
						font-weight: bolder;
					}

					.modal-close-custom {
						cursor: pointer;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 480px) {

	#main,
	#new-release {
		padding: 4em 2em 2.5em 2em;
		width: 100%;
	}
}

/* Footer */
#footer {
	-moz-align-self: -moz-flex-end;
	-webkit-align-self: -webkit-flex-end;
	-ms-align-self: -ms-flex-end;
	align-self: flex-end;
	width: 100%;
	padding: 1.5em 0 0 0;
	color: rgba(255, 255, 255, 0.75);
	cursor: default;
	text-align: center;
	text-transform: none;
	padding-bottom: 1.2rem;
}

.footer-fixed {
	position: fixed;
	bottom: 0;
}

#footer .copyright {
	margin: 0;
	padding: 0;
	font-size: 0.9em;
	list-style: none;
}

#footer .copyright li {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 0 0 0.45em;
	padding: 0 0 0 0.85em;
	line-height: 1;

	.uy-flag img {
		display: flex;
		width: 18px;
		height: 18px;
	}
}

#footer .copyright li:first-child {
	border-left: 0;
}

/* Wrapper */
#wrapper {
	min-height: 100vh;
}

.loading-spinner {
	display: flex;
	justify-content: center;
	align-items: center;
}

.hero-video {
	position: fixed;
	right: 0;
	bottom: 0;
	top: 0;
	left: 0;
	z-index: -1;
	pointer-events: none;
}

.video-volume {
	position: absolute;
	right: 0;
	bottom: 0;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 20px;
	border: 1px solid $white;
	z-index: 2 !important;
}

.video-volume i::before {
	color: $white;
	font-size: xx-large;
	font-weight: 900;
	width: 25px;
	height: 25px;
}

.video-volume:hover {
	cursor: pointer;
	background-color: $majalMain;
}

.jaes-made-it {
	text-transform: none;
}

.jaes-made-it a:hover {
	color: $red;
	font-weight: 600;
}

@media (min-aspect-ratio : 16/9) {
	.hero-video {
		width: 100%;
		height: 100vh;
		object-fit: cover;
	}
}

@media (max-aspect-ratio : 16/9) {
	.hero-video {
		width: 100vw;
		height: 100vh;
		object-fit: cover;
	}
}